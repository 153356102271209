.chk-box {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.chk-box:hover input ~ .checkmark {
  background-color: #ccc;
  cursor: pointer;
}
.chk-box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.chk-box .checkmark {
  transform: scale(0.8);
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: rgb(122, 122, 122);
  border-radius: 3px;
}
.chk-box input:checked ~ .checkmark {
  background-color: #44be63;
}
.chk-box .checkmark:after {
  content: '';
  position: absolute;
  left: 9px;
  top: 5px;
  height: 13px;
  width: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
