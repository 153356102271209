.react-time-picker__wrapper {
  border-radius: 0.5rem;
  @apply border-sky-800;
}

.react-time-picker__wrapper input {
  padding-inline: 10px;
  outline: none;
}

.react-time-picker__wrapper span {
  margin-inline: 3px;
}
