.react-datepicker__month {
  min-height: 200px;
}

.react-datepicker {
  font-size: 1.1rem;
}

.react-datepicker {
  font-family: "Open Sans";
}

.react-datepicker .react-datepicker__header {
  background-color: white;
  border: none;
}

div.react-datepicker {
  border: none;
}

.react-datepicker .react-datepicker__day:hover {
  border-radius: 50%;
}

.date .react-datepicker__day,
.date .react-datepicker__day-name {
  justify-content: space-around;
  width: 3.3rem;
  line-height: 3.3rem;
}
.react-datepicker__day {
  font-size: 0.8rem;
}

.react-datepicker .react-datepicker__day-name {
  color: #718096;
}

.react-datepicker .react-datepicker__day--in-range,
.react-datepicker .react-datepicker__day--in-selecting-range {
  background-color: #282829 !important;
  color: white;
  border-radius: 50%;
}

.react-datepicker .react-datepicker__day--range-start,
.react-datepicker .react-datepicker__day--range-start:hover,
.react-datepicker .react-datepicker__day--range-end,
.react-datepicker .react-datepicker__day--range-end:hover,
.react-datepicker .react-datepicker__day--selected,
.react-datepicker .react-datepicker__day--selected:hover,
.react-datepicker .react-datepicker__day--keyboard-selected,
.react-datepicker .react-datepicker__day--keyboard-selected:hover {
  background-color: #282829 !important;
  color: white;
  border-radius: 50%;
  outline: none;
}

.Toastify .Toastify__toast-container {
  width: max-content;
}

.Toastify .Toastify__toast {
  padding: 0px;
  min-height: fit-content;
  border-radius: 12px;
}

.Toastify .Toastify__toast-body {
  padding: 0px;
  border-radius: 12px;
}

.react-datepicker-popper div.react-datepicker {
  box-shadow: 0px 4px 5px rgba(117, 122, 95, 0.4);
  border-radius: 10px;
}

.date .react-datepicker__day,
.date .react-datepicker__day-name {
  width: 3rem;
  line-height: 3rem;
}

@media only screen and (max-width: 600px) {
  .date .react-datepicker__day,
  .date .react-datepicker__day-name {
    width: 2.25rem;
    line-height: 2.25rem;
  }
}

.react-datepicker .react-datepicker__day-name,
div.react-datepicker__day {
  font-size: 12px;
}