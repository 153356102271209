.table-skeleton-wrapper {
  background-color: white;
  border-radius: 5px;
}
.skeleton-card-wrapper {
  width: 15%;
}
.card {
  width: 100%;
  height: 40px;
  border-radius: 3px;
  background-image: linear-gradient(90deg, #e2e2e2 0px, #efefef 30px, #e2e2e2 60px);
  background-size: calc(120px);
  animation: shining 1.2s infinite ease-out;
}
